<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<topHeader></topHeader>

			<!-- <div class="ac_detail_top">
				<img :src="item.pic" />
			</div> -->
			<hostMore
				v-if="showHost"
				@hiddenHostMore="hiddenHostMore()"
				:tipTitle="tipTitle"
				:items="tmpArray"
			>
			</hostMore>
			<div class="ac_detail_ticket">
				<div class="ac_detail_ticket_left">
					<img :src="item.pic" />
				</div>
				<div class="ac_detail_ticket_right">
					<div class="ac_detail_ticket_title">{{ item.title }}</div>
					<div class="ac_detail_ticket_date">
						<div class="ac_detail_ticket_calendar">
							<div class="ac_detail_ticket_calendar_m">
								{{ getMonthStr(item.start_time) }}
							</div>
							<div class="ac_detail_ticket_calendar_d">
								{{ getDayStr(item.start_time) }}
							</div>
						</div>
						<div class="ac_detail_ticket_info">
							<div class="ac_detail_ticket_info_item">
								<img
									src="https://zy.metaera.media/assets/images/active/15_1.png"
								/>
								<span>{{ item.address }}</span>
							</div>
							<div class="ac_detail_ticket_info_item">
								<img
									src="https://zy.metaera.media/assets/images/active/16.png"
								/>
								<span
									>{{ formatDate(item.start_time) }} 至
									{{ formatDate(item.end_time) }} {{ item.timeZone }}</span
								>
							</div>
							<div
								class="ac_detail_ticket_info_item"
								v-if="item.url != undefined && item.url.length > 0"
							>
								<img
									src="https://zy.metaera.media/assets/images/active/17.png"
								/>
								<span
									class="ac_detail_ticket_info_item_url pointer"
									style="color: #0256ff"
									@click="showUrl(item.url)"
								>
									{{ item.url }}
								</span>
							</div>
						</div>
					</div>
					<div class="ac_detail_ticket_people">
						<div class="ac_detail_ticket_people_item">
							<div class="ac_detail_ticket_people_title">
								<div class="ac_detail_ticket_people_title_1">主办方</div>
								<div
									class="ac_detail_ticket_people_title_2"
									style="display: none"
									@click="hostMore(hostsArray, 'Host')"
									>更多</div
								>
							</div>
							<div class="ac_detail_ticket_people_list">
								<div class="ac_detail_ticket_people_list_sub">
									<div v-for="(item, index) in hostsArray" :key="index">
										<div class="ac_detail_ticket_people_list_item">
											<img :src="item.pic" />
											<span>{{ item.name }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ac_detail_ticket_people_item">
							<div class="ac_detail_ticket_people_title">
								<div class="ac_detail_ticket_people_title_1">协办方</div>
								<div
									class="ac_detail_ticket_people_title_2"
									style="display: none"
									@click="hostMore(coHostsArray, '协办方')"
									>更多</div
								>
							</div>
							<div class="ac_detail_ticket_people_list">
								<div class="ac_detail_ticket_people_list_sub">
									<div v-for="(item, index) in coHostsArray" :key="index">
										<div class="ac_detail_ticket_people_list_item">
											<img :src="item.pic" />
											<span>{{ item.name }}</span>
										</div>
									</div>
								</div>
								<!-- <div class="ac_detail_ticket_people_list_sub">
									<div v-for="(item, index) in coHostsArray" :key="index">
										<div
											class="ac_detail_ticket_people_list_item"
											v-if="index == 1 || index == 3"
										>
											<img :src="item.pic" />
											<span>{{ item.name }}</span>
										</div>
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="ac_detail_rigister" v-if="checkLogin()">
				<div class="ac_detail_rigister_title">
					<span>您好，</span>{{ $store.state.user.name }},
					<span>如需参与请先注册</span>
				</div>
				<div class="ac_detail_rigister_info">
					<img
						:src="
							$store.state.user.avatar && $store.state.user.avatar.length > 0
								? $store.state.user.avatar
								: 'https://zy.metaera.media/assets/images/tab/tab_mine_b1.png'
						"
					/>
					<span>{{ $store.state.user.name }}</span>
				</div>
				<div class="ac_detail_rigister_action pointer" @click="gotobuy()">
					<span>注册活动</span>
				</div>
			</div>

			<div class="ac_detail_login" v-if="!checkLogin()">
				<div class="ac_detail_login_title"> 请注册并登录参加活动 </div>
				<div class="ac_detail_login_action pointer" @click="gotobuy()">
					<span>注册活动</span>
				</div>
			</div>

			<div class="ac_detail_overview">
				<div class="ac_detail_overview_title">Event Overview</div>

				<div class="ac_detail_overview_content">
					<p class="ql-editor" v-html="item.content"></p>
				</div>
			</div>
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import { getToken, getUserMobile } from "@/utils/auth";
import store from "@/store";
import { acitvityDetail } from "@/api/home";
import topHeader from "../../components/pc/topHeader.vue";
import bottom from "../../components/pc/bottom.vue";
import hostMore from "./ac_host_more.vue";

export default {
	name: "",
	components: {
		topHeader,
		bottom,
		hostMore,
	},
	data() {
		return {
			token: getToken(),
			item: {},
			showHost: false,
			tmpArray: [],
			tipTitle: "",
			loading: false,
			id: "",
			hostsArray: [],
			coHostsArray: [],
		};
	},
	mounted() {
		this.token = getToken();
	},
	created() {
		this.token = getToken();
		this.id = this.$route.query.id;
		this.getAcitvityDetail();
	},
	methods: {
		hostMore(tmpArray, tipTitle) {
			this.tipTitle = tipTitle;
			this.tmpArray = tmpArray;
			this.showHost = true;
		},
		hiddenHostMore() {
			this.showHost = false;
		},
		checkLogin() {
			if (this.token == 0 || this.token == undefined) {
				return false;
			} else {
				return true;
			}
		},

		handleScroll(event) {},
		showUrl(url) {
			if (url == undefined || url.length == 0) {
				return;
			}
			window.open(url, "");
		},
		gotobuy() {
			if (this.token == 0 || this.token == undefined) {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			} else {
				this.$router.push({
					path: "/ac_ticket",
					query: { id: this.id },
				});
			}
		},
		getMonthStr(time) {
			const timeDate = new Date(time);

			const monthNames = [
				"JANUARY",
				"FEBRUARY",
				"MARCH",
				"APRIL",
				"MAY",
				"JUNE",
				"JULY",
				"AUGUST",
				"SEPTEMBER",
				"OCTOBER",
				"NOVEMBER",
				"DECEMBER",
			];
			return monthNames[timeDate.getMonth()];
		},
		getDayStr(time) {
			const timeDate = new Date(time);
			return timeDate.getDate();
		},
		goBack() {
			if (window.history.length > 1) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		getAcitvityDetail() {
			let obj = { id: this.id };
			acitvityDetail(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						this.item = res.data;
						this.hostsArray = JSON.parse(res.data.organizer);
						this.coHostsArray = JSON.parse(res.data.host);
					} else {
						this.$message.error("获取活动详情失败，请重新获取");
						this.goBack();
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hour12: false,
			});
		},
	},
};
</script>

<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: white;
}
</style>

<!-- ac_detail_top -->
<style scoped>
.ac_detail_top {
	margin-top: 100px;
	width: 100%;
	background: url(https://zy.metaera.media/assets/images/active/13.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	position: relative; /* 为了让伪元素相对于这个容器定位 */
}
.ac_detail_top img {
	margin-top: 80px;
	margin-bottom: 80px;
	width: 60%;
	margin-left: 20%;
	border-radius: 20px;
}
</style>

<!-- ac_detail_ticket -->
<style scoped>
.ac_detail_ticket {
	margin-top: 120px;
	width: 100%;
	display: flex;
}
.ac_detail_ticket_left {
	margin-left: 10%;
	margin-right: 2%;
	margin-top: 80px;
	margin-bottom: 80px;
	width: 38%;
}

.ac_detail_ticket_left img {
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.ac_detail_ticket_right {
	width: 38%;
	margin-left: 2%;
	margin-top: 80px;
	margin-bottom: 80px;
	/* display: flex; */
	flex-direction: column;
	justify-content: space-between;
}
.ac_detail_ticket_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 34px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.ac_detail_ticket_date {
	display: flex;
	margin-top: 30px;
}

.ac_detail_ticket_calendar {
	width: 100px;
	/* height: 85px; */
}

.ac_detail_ticket_calendar_m {
	background: #0256ff;
	width: 100%;
	height: 33px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: white;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: bold;
	/* display: flex; */
	align-content: center;
	align-items: center;
	text-align: center;
}
.ac_detail_ticket_calendar_d {
	background: #e6e4e2;
	width: 100%;
	height: 50px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	color: #333333;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 34px;
	font-weight: bold;
	/* display: flex; */
	align-content: center;
	align-items: center;
	text-align: center;
}

.ac_detail_ticket_info {
	margin-left: 30px;
}
.ac_detail_ticket_info_item {
	margin-bottom: 6px;
}
.ac_detail_ticket_info_item img {
	width: 16px;
	height: 16px;
	object-fit: contain;
}
.ac_detail_ticket_info_item span {
	margin-left: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}

.ac_detail_ticket_info_item_url {
	color: #0256ff !important;
}

.ac_detail_ticket_people {
	margin-top: 35px;
	display: flex;
	justify-content: space-between;
	/* margin-top: auto; */
	display: flex;
	flex: 0 1 auto;
}

.ac_detail_ticket_people_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_detail_ticket_people_item {
	width: 48%;
}

.ac_detail_ticket_people_title_1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_detail_ticket_people_title_2 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
	text-decoration: underline;
}

.ac_detail_ticket_people_list {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.ac_detail_ticket_people_list_sub {
	width: 90%;
	margin-top: 12px;
	justify-content: space-between;
}

.ac_detail_ticket_people_list_item {
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap; /* 允许文本换行 */
}

.ac_detail_ticket_people_list_item img {
	width: 40px;
	height: 40px;
	opacity: 1;
	object-fit: cover;
	border-radius: 20px;
	box-sizing: border-box;
	border: 1px solid #e2ebff;
	flex-shrink: 0; /* 防止图像缩小 */
}

.ac_detail_ticket_people_list_item span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
	flex: 1;
	word-break: break-word; /* 允许长单词换行 */
	white-space: normal; /* 允许文本换行 */
}
</style>

<!-- ac_detail_rigister -->
<style scoped>
.ac_detail_rigister {
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 50px;
	width: 80%;
	height: 220px;
	border-radius: 20px;
	padding-top: 24px;
	background: #ededed;
	text-align: center;
}
.ac_detail_rigister_title,
.ac_detail_rigister_title span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 20px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_detail_rigister_info {
	display: inline-block;
	height: 52px;
	border-radius: 13px;
	align-items: center;
	align-content: center;
	margin-top: 25px;
	margin-bottom: 20px;
	box-sizing: border-box;
	/* 主要色/主要色 */
	border: 1px solid #0256ff;
}
.ac_detail_rigister_info img {
	border-radius: 13px;
	margin-left: 20px;
	width: 40px;
	height: 40px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #e2ebff;
}

.ac_detail_rigister_info span {
	margin-left: 10px;
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_detail_rigister_action {
	width: 100%;
	height: 80px;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_detail_rigister_action span {
	border-radius: 10px;
	opacity: 1;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	/* 主要色/主要色 */
	background: #0256ff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>

<!-- ac_detail_login -->
<style scoped>
.ac_detail_login {
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 50px;
	width: 80%;
	height: 150px;
	border-radius: 20px;
	padding-top: 24px;
	background: #ededed;
	text-align: center;
}
.ac_detail_login_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 25px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_detail_login_action {
	width: 100%;
	height: 60px;
	margin-top: 30px;
	text-align: center;
	align-content: center;
	align-items: center;
	opacity: 0.5;
}

.ac_detail_login span {
	border-radius: 10px;
	opacity: 1;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
	/* 主要色/主要色 */
	background: #0256ff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>

<!-- ac_detail_overview -->
<style scoped>
.ac_detail_overview {
	width: 80%;
	margin-left: 10%;
	margin-bottom: 100px;
}
.ac_detail_overview_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: normal;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #1a1a1a;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 5px; /* 设置下划线与文字之间的距离 */
}
.ac_detail_overview_content {
	margin-top: 40px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: justify;
	font-variation-settings: "opsz" auto;
	color: #333333;
}
</style>
<style></style>
